<template>
  <div class="w-100 position-relative">
    <CCard class="">
      <div class="">
        <div class="pdf-wrapper">
          <div class="pdf-page fs-16">
            <div id="pdf-2" class="pdf-content ml-3 mr-3">
              <div class="text-center fs-16">
                <strong class="bold">Mẫu C.III.1 <br>
                  Văn bản về phương án xúc tiến đầu tư trong hoạt động đối ngoại cấp cao nhà nước</strong>
                <p class="font-italic">(Khoản 1, Điều 94 Nghị định số 31/2021/NĐ-CP ngày 26/3/2021)</p>
                <hr>
              </div>
              <div class="row fs-16">
                <div class="col text-center">
                  <p class="font-weight-bold text-uppercase">{{ item.tenDonViToChuc }}</p>
                  <hr width="150px" style="border: 1px double">
                  <p class="mr-3">Số: {{ item.soVanBan }}</p>
                  <div class="" style="width: 13em; margin-left: 6em">
                    <p class="font-weight align-middle">V/v phương án xúc tiến đầu tư trong hoạt động đối ngoại cấp cao nhà nước</p>
                  </div>
                </div>
                <div class="col text-center">
                  <span class="text-uppercase font-weight-bold">Cộng hòa xã hội chủ nghĩa Việt Nam</span>
                  <p class="font-weight-bold">Độc lập – Tự do – Hạnh phúc</p>
                  <hr width="180px" style="border: 1px double">
                  <p class="font-weight font-italic" style="float: right">
                    {{ item.diaDiem }}, ngày {{ getDay(item.ngayVanBan) }} tháng {{ getMonth(item.ngayVanBan) }} năm
                    {{ getYear(item.ngayVanBan) }}
                  </p>
                </div>
              </div>
              <div class="text-center">
                <p>Kính gửi: Bộ Kế hoạch và Đầu tư</p>
              </div>
              <div class="ml-3">
                <p style="text-indent: 3em">Thực hiện chỉ đạo của.... tại công văn số.... ngày..,
                  <span class="font-italic"> {{ item.tenDonViToChuc }}</span>
                   được giao tổ chức thực hiện hoạt động đối ngoại cấp cao nhà nước trong đó có hoạt động xúc tiến đầu tư.
                </p>
                <p style="text-indent: 3em">Căn cứ quy định tại Khoản 1 Điều 94 Nghị định số 31/2021/NĐ-CP ngày 26/3/2021 quy định chi tiết và hướng dẫn thi hành một số điều của Luật Đầu tư,
                  <span class="font-italic">{{ item.tenDonViToChuc }}</span>
                  đề nghị Bộ Kế hoạch và Đầu tư có ý kiến về Phương án xúc tiến đầu tư trong hoạt động đối ngoại cấp cao nhà nước, cụ thể như sau:
                </p>
              </div>
              <div style="text-indent: 3em">
                <div>
                  <p>1. Tên hoạt động xúc tiến đầu tư: {{ item.tenHoatDong }}</p>
                </div>
<!--                <div>-->
<!--                  <p>2. Mục tiêu: {{ item.mucTieu }}</p>-->
<!--                </div>-->
                <div>
                  <p>2. Thời gian thực hiện: {{ getDate(item.thoiGianThucHien) }}</p>
                </div>
                <div>
                  <p>3. Địa điểm (trong nước/nước ngoài):</p>
                  <p style="text-indent: 6em">Trong nước: {{ displayLabel(optionsTinhThanh, item.diaDiem_TrongNuoc) }}</p>
                  <p style="text-indent: 6em">Nước ngoài: {{ displayLabel(optionsQuocGia, item.diaDiem_NuocNgoai) }}</p>
                </div>
                <div>
                  <p>4. Thành phần dự kiến tham gia:</p>
                  <p style="text-indent: 6em">{{ item.thanhPhanDuKienThamGia }}</p>
                </div>
                <div>
                  <p>5. Quy mô hoạt động:</p>
                  <p style="text-indent: 6em">{{ item.quyMoHoatDong }}</p>
                </div>
                <div>
                  <p>6. Lĩnh vực/Địa bàn kêu gọi đầu tư:</p>
                  <p style="text-indent: 6em">Ngành/lĩnh vực kêu gọi đầu tư: {{ displayLabel(optionsNganhLinhVuc, item.nganhLinhVucKeuGoiDauTuId) }}</p>
                  <p style="text-indent: 6em">Địa bàn kêu gọi đầu tư: {{ displayLabel(optionsTinhThanh, item.diaBanKeuGoiDauTu) }}</p>
                </div>
                <div>
                  <p>7. Các hoạt động chính:
                    <span class="font-italic">(bao gồm danh mục giấy chứng nhận đăng ký đầu tư/chấp thuận chủ trương đầu tư/thỏa thuận  <br>
                      <span style="margin-left: 2em">dự kiến trao/ký kết)</span></span>
                  </p>
                  <p style="text-indent: 6em">{{ item.cacHoatDongChinh }}</p>
                </div>
                <div>
                  <p>8. Cơ quan, tổ chức, doanh nghiệp, cá nhân phối hợp thực hiện:</p>
                  <p style="text-indent: 6em">Tên tổ chức/cơ quan, doanh nghiệp, cá nhân trong nước: {{ item.toChucCoQuan_TrongNuoc }}</p>
                  <p style="text-indent: 6em">Tên tổ chức/cơ quan, doanh nghiệp, cá nhân nước ngoài: {{ item.toChucCoQuan_NuocNgoai }}</p>
                </div>
                <div>
                  <p>9. Chương trình dự kiến:</p>
                  <p style="text-indent: 6em">{{ item.chuongTrinhDuKien }}</p>
                </div>
                <div>
                  <p>10. Phương án tổ chức thực hiện:</p>
                  <p style="text-indent: 6em">{{ item.phuongAnToChucThucHien }}</p>
                </div>
                <div>
                  <p>11. Kinh phí:</p>
                  <p style="text-indent: 6em">Ngân sách: {{ item.kinhPhi_NganSach | formatNumber }}</p>
                  <p style="text-indent: 6em">Nguồn xã hội hóa: {{ item.kinhPhi_XaHoiHoa | formatNumber }}</p>
                </div>
                <div>
                  <p>12. Thông tin liên hệ:</p>
                  <p style="text-indent: 6em">Họ tên: {{ item.lienHe_HoTen }}</p>
                  <p style="text-indent: 6em">Email: {{ item.lienHe_Email }}</p>
                  <p style="text-indent: 6em">Số điện thoại: {{ item.lienHe_SoDienThoai }}</p>
                </div>
                <div class="ml-3">
                  <p style="text-indent: 3em">
                    Đề nghị Bộ Kế hoạch và Đầu tư xem xét, có ý kiến về các nội dung nêu trên, để <span class="font-italic">{{ item.tenBo_UBNDTinh }}</span>) có cơ sở triển khai tổ chức thực hiện.
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col ml-3">
                  <span class="font-italic font-weight-bold">Nơi nhận:</span> <br>
                  <span class="font-weight">- Như trên;</span> <br>
                  <span class="font-weight">- Lưu: VT,.......</span> <br>
                </div>
                <div class="col">
                  <div class="float-right mr-5" style="text-align: center">
                    <span class="font-weight-bold text-uppercase">Thủ trưởng cơ quan chủ trì</span> <br>
                    <span class="font-italic text-align">(Ký, ghi rõ họ tên, chức danh và đóng dấu)</span><br><br><br>
                    <br>
                    <br>
                  </div>
                </div>
              </div>
            </div>
            <div class="print-btn close-button">
              <button class="print-button" @click="print">
                <span class="print-icon"></span></button>
            </div>
          </div>
        </div>
        <!--        <CCardFooter tag="div" class="text-center">-->
        <!--          <CButton color="secondary" class="mr-2" @click="back">Quay lại</CButton>-->
        <!--          <CButton color="danger" @click="isDeleteConfirmationModalOpen = true">Xóa</CButton>-->
        <!--        </CCardFooter>-->
      </div>
    </CCard>
  </div>
  <!--  <div class="print-page w-100 position-relative" style="overflow-y: auto" :class="$store.state.printThongBaoToChuc.class" :style="$store.state.printThongBaoToChuc.style">-->
  <!--    <div class="report">-->
  <!--      <div class="pdf-wrapper">-->
  <!--        <div class="pdf-page fs-16">-->
  <!--          <div id="pdf1" class="pdf-content ml-3 mr-3">-->
  <!--            <div class="text-center fs-16">-->
  <!--              <strong class="bold">Mẫu C.III.3</strong><br>-->
  <!--              <strong>Văn bản thông báo kế hoạch tổ chức hoạt động</strong><br>-->
  <!--              <strong>xúc tiến không sử dụng ngân sách nhà nước</strong><br>-->
  <!--              <p class="font-italic">(Khoản 3, Điều 96 Nghị định số 31/2021/NĐ-CP ngày 26/3/2021)</p>-->
  <!--              <hr>-->
  <!--            </div>-->
  <!--            <div class="row fs-16">-->
  <!--              <div class="col-md-6">-->
  <!--                <p>{{item.tenDonViToChuc}}</p>-->
  <!--                <p>{{item.soVanBan}}</p>-->
  <!--                &lt;!&ndash;                  <CInput class="custom-input" v-model="item.tenDonViToChuc" placeholder="TÊN ĐƠN VỊ TỔ CHỨC/CÁ NHÂN"&ndash;&gt;-->
  <!--                &lt;!&ndash;                          horizontal/>&ndash;&gt;-->
  <!--                &lt;!&ndash;                  <CInput class="custom-input" placeholder="Số:" v-model="item.soVanBan" horizontal/>&ndash;&gt;-->
  <!--              </div>-->
  <!--              &lt;!&ndash;                <div class="col-md-4"/>&ndash;&gt;-->
  <!--              <div class="col-md-6">-->
  <!--                <p class="text-center text-uppercase font-weight-bold">Cộng hòa xã hội chủ nghĩa Việt Nam</p>-->
  <!--                <p class="text-center font-weight-bold">Độc lập – Tự do – Hạnh phúc</p>-->
  <!--                <p class="font-weight font-italic" style="text-align: center">-->
  <!--                  {{item.diaDiem}}, ngày {{ getDay(item.ngayVanBan)}} tháng {{ getMonth(item.ngayVanBan)}} năm {{ getYear(item.ngayVanBan) }}-->
  <!--                  &lt;!&ndash;                    <CInput placeholder="Địa điểm:" horizontal :value.sync="item.diaDiem"/>&ndash;&gt;-->
  <!--                  &lt;!&ndash;                    <CInput type="date" placeholder="Ngày văn bản:" horizontal :value.sync="item.ngayVanBan"/>&ndash;&gt;-->
  <!--                  &lt;!&ndash;                    {{item.diaDiem}}, ngày ... tháng ... năm ...&ndash;&gt;-->
  <!--                  &lt;!&ndash;                    <span class="mt-2">, ngày {{getDay(item.ngayVanBan) }} tháng {{ getMonth(item.ngayVanBan) }} năm {{ getYear(item.ngayVanBan) }}</span>&ndash;&gt;-->
  <!--                </p>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--            <div class="row">-->
  <!--              <div class="col-md-4" style="float: left">-->
  <!--                <p class="font-weight">V/v thông báo kế hoạch tổ chức-->
  <!--                  hoạt động xúc tiến đầu tư-->
  <!--                </p>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--            <div class="text-center">-->
  <!--              <p>Kính gửi: Bộ Kế hoạch và Đầu tư</p>-->
  <!--            </div>-->
  <!--            <div class="ml-3">-->
  <!--              <p><span class="pl-3">Căn cứ quy</span> định tại Khoản 3 Điều 96 Nghị định số 31/2021/NĐ-CP ngày-->
  <!--                26/3/2021 quy định chi tiết và hướng dẫn thi hành một số điều của Luật Đầu tư, <span-->
  <!--                  class="font-italic">(Tên đơn vị tổ chức/Cá nhân)</span>-->
  <!--                xin thông báo kế hoạch tổ chức các hoạt động xúc tiến đầu tư như sau-->
  <!--              </p>-->
  <!--            </div>-->
  <!--            <div class="row">-->
  <!--              <div class="col-xl-12" style="display: flex">-->
  <!--                <div class="col-4">-->
  <!--                  <label>1. Tên hoạt động xúc tiến đầu tư:</label>-->
  <!--                </div>-->
  <!--                <div class="col-8">-->
  <!--                  {{item.tenHoatDong}}-->
  <!--                  &lt;!&ndash;                    <CInput placeholder="Nhập tên hoạt động đầu tư" horizontal v-model="item.tenHoatDong"/>&ndash;&gt;-->
  <!--                </div>-->
  <!--              </div>-->
  <!--              <div class="col-xl-12" style="display: flex">-->
  <!--                <div class="col-4">-->
  <!--                  <label>2. Mục tiêu:</label>-->
  <!--                </div>-->
  <!--                <div class="col-8">-->
  <!--                  {{item.mucTieu}}-->
  <!--                  &lt;!&ndash;                    <CInput placeholder="Nhập mục tiêu" horizontal v-model="item.mucTieu"/>&ndash;&gt;-->
  <!--                </div>-->
  <!--              </div>-->
  <!--              <div class="col-xl-12" style="display: flex">-->
  <!--                <div class="col-4">-->
  <!--                  <label>3. Thời gian thực hiện:</label>-->
  <!--                </div>-->
  <!--                <div class="col-4">-->
  <!--                  {{getDate(item.thoiGianThucHien)}}-->
  <!--                  &lt;!&ndash;                    <v-date-picker class="" v-model="item.thoiGianThucHien" locale="vi" type="datetime">&ndash;&gt;-->
  <!--                  &lt;!&ndash;                      <template v-slot="{ inputValue, inputEvents }">&ndash;&gt;-->
  <!--                  &lt;!&ndash;                        <CInput&ndash;&gt;-->
  <!--                  &lt;!&ndash;                          :value="inputValue"&ndash;&gt;-->
  <!--                  &lt;!&ndash;                          v-on="inputEvents"&ndash;&gt;-->
  <!--                  &lt;!&ndash;                          placeholder="Chọn ngày"&ndash;&gt;-->
  <!--                  &lt;!&ndash;                        />&ndash;&gt;-->
  <!--                  &lt;!&ndash;                      </template>&ndash;&gt;-->
  <!--                  &lt;!&ndash;                    </v-date-picker>&ndash;&gt;-->
  <!--                </div>-->
  <!--              </div>-->
  <!--              <div class="col-xl-12">-->
  <!--                <label class="ml-3">4. Địa điểm (trong nước/nước ngoài):</label>-->
  <!--                <div class="col-xl-12" style="display: flex">-->
  <!--                  <div class="col-4">-->
  <!--                    <ul>-->
  <!--                      <li>Trong nước:</li>-->
  <!--                      <li class="mt-4">Nước ngoài:</li>-->
  <!--                    </ul>-->
  <!--                  </div>-->
  <!--                  <div class="col-8">-->
  <!--                    <p>{{item.diaDiem_TrongNuoc}}</p>-->
  <!--                    <p>{{item.diaDiem_NuocNgoai}}</p>-->
  <!--                    &lt;!&ndash;                      <CSelect class="form-group" placeholder="Chọn tỉnh thành" :value.sync="item.diaDiem_TrongNuoc"&ndash;&gt;-->
  <!--                    &lt;!&ndash;                               :options="optionsTinhThanh" horizontal/>&ndash;&gt;-->
  <!--                    &lt;!&ndash;                      <CSelect class="form-group" placeholder="Chọn quốc gia" :value.sync="item.diaDiem_NuocNgoai"&ndash;&gt;-->
  <!--                    &lt;!&ndash;                               :options="optionsQuocGia" horizontal/>&ndash;&gt;-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--              <div class="col-xl-12" style="display: flex">-->
  <!--                <div class="col-4">-->
  <!--                  <label>5. Thành phần dự kiến tham gia:</label>-->
  <!--                </div>-->
  <!--                <div class="col-8">-->
  <!--                  {{item.thanhPhanDuKienThamGia}}-->
  <!--                  &lt;!&ndash;                    <CInput class="form-group" v-model="item.thanhPhanDuKienThamGia"&ndash;&gt;-->
  <!--                  &lt;!&ndash;                            placeholder="Nhập các thành phần dự kiến tham gia" horizontal/>&ndash;&gt;-->
  <!--                </div>-->
  <!--              </div>-->
  <!--              <div class="col-xl-12" style="display: flex">-->
  <!--                <div class="col-4">-->
  <!--                  <label>6. Quy mô hoạt động:</label>-->
  <!--                </div>-->
  <!--                <div class="col-8">-->
  <!--                  {{item.quyMoHoatDong}}-->
  <!--                  &lt;!&ndash;                    <CInput class="form-group" v-model="item.quyMoHoatDong" placeholder="Nhập quy mô hoạt động"&ndash;&gt;-->
  <!--                  &lt;!&ndash;                            horizontal/>&ndash;&gt;-->
  <!--                </div>-->
  <!--              </div>-->
  <!--              <div class="col-xl-12">-->
  <!--                <label class="ml-3">7. Lĩnh vực/Địa bàn kêu gọi đầu tư:</label>-->
  <!--                <div class="col-xl-12" style="display: flex">-->
  <!--                  <div class="col-4">-->
  <!--                    <ul>-->
  <!--                      <li>Ngành/Lĩnh vực kêu gọi đầu tư:</li>-->
  <!--                      <li class="mt-4">Địa bàn kêu gọi đầu tư:</li>-->
  <!--                    </ul>-->
  <!--                  </div>-->
  <!--                  <div class="col-8">-->
  <!--                    <p>{{item.nganhLinhVucKeuGoiDauTuId}}</p>-->
  <!--                    <p>{{item.diaBanKeuGoiDauTu}}</p>-->
  <!--                    &lt;!&ndash;                      <CSelect class="form-group" placeholder="Chọn ngành/lĩnh vực"&ndash;&gt;-->
  <!--                    &lt;!&ndash;                               :value.sync="item.nganhLinhVucKeuGoiDauTuId" horizontal :options="optionsNganhLinhVuc"/>&ndash;&gt;-->
  <!--                    &lt;!&ndash;                      <CSelect class="form-group" placeholder="Chọn địa bàn" :value.sync="item.diaBanKeuGoiDauTu" horizontal&ndash;&gt;-->
  <!--                    &lt;!&ndash;                               :options="optionsTinhThanh"/>&ndash;&gt;-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--              <div class="col-xl-12 ml-3">-->
  <!--                <label>8. Các hoạt động chính:-->
  <!--                  <span class="font-italic">(bao gồm danh mục giấy chứng nhận đăng ký đầu tư/chấp thuận chủ trương đầu-->
  <!--                      tư/thỏa thuận dự kiến trao/ký kết)</span>-->
  <!--                </label>-->
  <!--                <div class="col-12">-->
  <!--                  {{item.cacHoatDongChinh}}-->
  <!--                  &lt;!&ndash;                    <CInput class="form-group" placeholder="Nhập các hoạt động chính" horizontal&ndash;&gt;-->
  <!--                  &lt;!&ndash;                            v-model="item.cacHoatDongChinh"/>&ndash;&gt;-->
  <!--                </div>-->
  <!--              </div>-->
  <!--              <div class="col-xl-12">-->
  <!--                <label class="ml-3">9. Cơ quan, tổ chức, doanh nghiệp, cá nhân phối hợp thực hiện:</label>-->
  <!--                <div class="col-xl-12" style="display: flex">-->
  <!--                  <div class="col-4">-->
  <!--                    <ul>-->
  <!--                      <li>Tên tổ chức/cơ quan, doanh nghiệp, cá nhân trong nước:</li>-->
  <!--                      <li class="mt-4">Tên tổ chức/cơ quan, doanh nghiệp, cá nhân nước ngoài:</li>-->
  <!--                    </ul>-->
  <!--                  </div>-->
  <!--                  <div class="col-8">-->
  <!--                    <p>{{item.toChucCoQuan_TrongNuoc}}</p>-->
  <!--                    <br>-->
  <!--                    <br>-->
  <!--                    <p>{{item.toChucCoQuan_NuocNgoai}}</p>-->
  <!--                    &lt;!&ndash;                      <CInput class="form-group" v-model="item.toChucCoQuan_TrongNuoc"&ndash;&gt;-->
  <!--                    &lt;!&ndash;                              placeholder="Chọn tên tổ chức/cơ quan trong nước" horizontal&ndash;&gt;-->
  <!--                    &lt;!&ndash;                      />&ndash;&gt;-->
  <!--                    &lt;!&ndash;                      <CInput class="form-group" v-model="item.toChucCoQuan_NuocNgoai"&ndash;&gt;-->
  <!--                    &lt;!&ndash;                              placeholder="Chọn tên tổ chức/cơ quan nước ngoài" horizontal&ndash;&gt;-->
  <!--                    &lt;!&ndash;                      />&ndash;&gt;-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--              <div class="col-xl-12 ml-3">-->
  <!--                <label>10. Chương trình dự kiến:</label>-->
  <!--                <div class="col-12">-->
  <!--                  {{item.chuongTrinhDuKien}}-->
  <!--                  &lt;!&ndash;                    <CInput class="form-group" v-model="item.chuongTrinhDuKien" placeholder="Nhập chương trình dự kiến"&ndash;&gt;-->
  <!--                  &lt;!&ndash;                            horizontal&ndash;&gt;-->
  <!--                  &lt;!&ndash;                    />&ndash;&gt;-->
  <!--                </div>-->
  <!--              </div>-->
  <!--              <div class="col-xl-12 ml-3">-->
  <!--                <label>11. Phương án tổ chức thực hiện:</label>-->
  <!--                <div class="col-12">-->
  <!--                  {{item.phuongAnToChucThucHien}}-->
  <!--                  &lt;!&ndash;                    <CInput class="form-group" v-model="item.phuongAnToChucThucHien"&ndash;&gt;-->
  <!--                  &lt;!&ndash;                            placeholder="Nhập phương án tổ chức thực hiện" horizontal&ndash;&gt;-->
  <!--                  &lt;!&ndash;                    />&ndash;&gt;-->
  <!--                </div>-->
  <!--              </div>-->
  <!--              <div class="col-xl-12">-->
  <!--                <label class="ml-3">11. Kinh phí:</label>-->
  <!--                <div class="col-xl-12" style="display: flex">-->
  <!--                  <div class="col-4">-->
  <!--                    <ul>-->
  <!--                      <li>Ngân sách:</li>-->
  <!--                      <li class="mt-4">Nguồn xã hội hóa:</li>-->
  <!--                    </ul>-->
  <!--                  </div>-->
  <!--                  <div class="col-8">-->
  <!--                    <p>{{item.kinhPhi_NganSach}}</p>-->
  <!--                    <p>{{item.kinhPhi_XaHoiHoa}}</p>-->
  <!--                    &lt;!&ndash;                      <CInput class="form-group" :value.sync="item.kinhPhi_NganSach"&ndash;&gt;-->
  <!--                    &lt;!&ndash;                              placeholder="Nhập kinh phí nguồn ngân sách" horizontal&ndash;&gt;-->
  <!--                    &lt;!&ndash;                      />&ndash;&gt;-->
  <!--                    &lt;!&ndash;                      <CInput class="form-group" :value.sync="item.kinhPhi_XaHoiHoa"&ndash;&gt;-->
  <!--                    &lt;!&ndash;                              placeholder="Nhập kinh phí nguồn xã hội hóa" horizontal/>&ndash;&gt;-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--              <div class="col-xl-12">-->
  <!--                <label class="ml-3">12. Thông tin liên hệ:</label>-->
  <!--                <div class="col-xl-12" style="display: flex">-->
  <!--                  <div class="col-2">-->
  <!--                    <ul>-->
  <!--                      <li>Họ tên:</li>-->
  <!--                      <li class="mt-4">Email:</li>-->
  <!--                    </ul>-->
  <!--                  </div>-->
  <!--                  <div class="col-4">-->
  <!--                    <p>{{item.lienHe_HoTen}}</p>-->
  <!--                    <p>{{item.lienHe_Email}}</p>-->
  <!--                    &lt;!&ndash;                      <CInput class="form-group" v-model="item.lienHe_HoTen" placeholder="Nhập họ tên" horizontal/>&ndash;&gt;-->
  <!--                    &lt;!&ndash;                      <CInput class="form-group" v-model="item.lienHe_Email" placeholder="Nhập email" horizontal/>&ndash;&gt;-->
  <!--                  </div>-->
  <!--                  <div class="col-2">-->
  <!--                    <ul>-->
  <!--                      <li class="">Số điện thoại:</li>-->
  <!--                    </ul>-->
  <!--                  </div>-->
  <!--                  <div class="col-4">-->
  <!--                    {{item.lienHe_SoDienThoai}}-->
  <!--                    &lt;!&ndash;                      <CInput class="form-group" v-model="item.lienHe_SoDienThoai" placeholder="Nhập số điện thoại"&ndash;&gt;-->
  <!--                    &lt;!&ndash;                              horizontal&ndash;&gt;-->
  <!--                    &lt;!&ndash;                      />&ndash;&gt;-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--              <div class="col-xl-12 row">-->
  <!--                <div class="col">-->
  <!--                  <div class="float-left">-->
  <!--                    <span class="font-italic font-weight-bold">Nơi nhận:</span> <br>-->
  <!--                    <span class="font-weight">- Như trên;</span> <br>-->
  <!--                    <span class="font-weight">- Bộ Kế Hoạch và Đầu tư (để t/b);</span> <br>-->
  <!--                    <span class="font-weight">- Lưu: VT,.......</span> <br>-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--              <div class="col-xl-12">-->
  <!--                <div class="float-right mr-5" style="text-align: center">-->
  <!--                  <span class="font-weight-bold text-uppercase">ĐẠI DIỆN CÓ THẨM QUYỀN CỦA ĐƠN VỊ TỔ CHỨC</span> <br>-->
  <!--                  <span class="font-italic text-align">(Ký, ghi rõ họ tên, chức danh và đóng dấu)</span><br>-->
  <!--                  <span class="font-weight-bold text-uppercase">CÁ NHÂN</span><br>-->
  <!--                  <span class="font-italic text-align">(Ký, ghi rõ họ tên)</span><br>-->
  <!--                  <br>-->
  <!--                  <br>-->
  <!--                  <br>-->
  <!--                  <br>-->
  <!--                  <br>-->
  <!--                  <span class="font-italic text-align">(Ký, ghi rõ họ tên, chức danh và đóng dấu)</span><br>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--              <div class="col-xl-12">-->
  <!--                <label>File đính kèm:</label>-->
  <!--                <div class="import-files mb-2" v-if="Attachments.length">-->
  <!--                  <div class="files mb-1" v-for="file in Attachments" v-bind:key="file.id">-->
  <!--                    <a :href="getDownloadURL(file.url)" target="_blank">{{ file.fileName }}</a>-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="header">-->
  <!--      </div>-->
  <!--      <div class="body">-->
  <!--        <div class="content">-->
  <!--          Thông báo tổ chức print-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="print-btn close-button">-->
  <!--        <button class="print-button" @click="close">-->
  <!--          <i class="cil-x"></i>-->
  <!--        </button>-->
  <!--      </div>-->
  <!--      <div class="print-btn">-->
  <!--        <button class="print-button" @click="print"><span class="print-icon"></span></button>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</template>

<script>
import { statuses, loaiDonVis, displayLabel } from '@/shared/appConstants'
import { mapGetters, mapActions } from 'vuex'
import {
  DELETE_THONGBAOTOCHUC,
  GET_THONGBAOTOCHUC
} from '@/store/modules/VanBanTBTCHDXTDTKSDNSNN/actionTypes'
import { tinhThanhService } from '@/services/tinh-thanh.service'
import { nganhLinhVucService } from '@/services/nganh-linh-vuc.service'
import { quocGiaService } from '@/services/quoc-gia.service'
import moment from 'moment'
import appConfig from '@/shared/appConfig'

export default {
  name: 'ThongBaoToChucPrint',
  data () {
    return {
      Attachments: [],
      type: 'ThongBaoToChuc',
      optionsTrangThai: statuses,
      optionsLoaiDonVi: loaiDonVis,
      isDeleteConfirmationModalOpen: false,
      optionsTinhThanh: [],
      optionsQuocGia: [],
      optionsNganhLinhVuc: [],
      item: {}
    }
  },
  computed: {
    ...mapGetters('thongBaoToChuc', {
      // item: 'detailItem',
      // isFetchingSingle: 'isFetchingSingle',
      isDeleting: 'isDeleting',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('thongBaoToChuc', {
      getItem: GET_THONGBAOTOCHUC,
      deleteItem: DELETE_THONGBAOTOCHUC
    }),
    displayLabel,
    getDownloadURL (url) {
      return url && (url.indexOf('http://') > -1 || url.indexOf('https://') > -1) ? url : appConfig.BASE_HOST + url
    },
    getDate: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('DD/MM/YYYY') : '',
    getDay: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('DD') : '',
    getMonth: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('MM') : '',
    getYear: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('YYYY') : '',
    back () {
      this.$router.push({ path: '/thong-bao-to-chuc-hd-xtdt' })
    },
    editItem () {
      this.$router.push({ path: `/thong-bao-to-chuc-hd-xtdt/${this.item.id}/cap-nhat` })
    },
    async confirmDestroy () {
      await this.deleteItem(this.item)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        this.isDeleteConfirmationModalOpen = false
        await this.$router.push({ path: '/thong-bao-to-chuc-hd-xtdt' })
      }
    },
    getLocalStorage () {
      const jsonData = localStorage.getItem('thong_bao_to_chuc')
      this.item = JSON.parse(jsonData)
      // console.log(this.item)
      if (this.item.dinhKem) this.Attachments = JSON.parse(this.item.dinhKem)
    },
    printVanBan () {
      window.print()
    },
    close () {
      const that = this
      this.$store.state.printThongBaoToChuc = {
        ...this.$store.state.printThongBaoToChuc,
        show: false,
        class: 'ms-motion-scaleDownOut'
      }
      setTimeout(() => {
        that.$store.state.printThongBaoToChuc.style = { display: 'none' }
      }, 300)
    },
    print () {
      window.print()
    }
  },
  async created () {
    if (this.$route.params.id) {
      await this.getItem(this.$route.params.id)
      // if (this.item.trangThai === 3) await this.getYKienPheDuyetGanNhat(this.$route.params.id)
    } else {
      await this.getLocalStorage()
    }
    if (this.item.dinhKem) this.Attachments = JSON.parse(this.item.dinhKem)
    this.optionsTinhThanh = await tinhThanhService.getAll()
    this.optionsNganhLinhVuc = await nganhLinhVucService.getAll()
    this.optionsQuocGia = await quocGiaService.getAll()
  }
}
</script>

<style scoped>

</style>
